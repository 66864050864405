exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-propelius-technology-tsx": () => import("./../../../src/pages/contact-propelius-technology.tsx" /* webpackChunkName: "component---src-pages-contact-propelius-technology-tsx" */),
  "component---src-pages-index-page-tsx": () => import("./../../../src/pages/index.page.tsx" /* webpackChunkName: "component---src-pages-index-page-tsx" */),
  "component---src-templates-about-us-tsx": () => import("./../../../src/templates/about-us.tsx" /* webpackChunkName: "component---src-templates-about-us-tsx" */),
  "component---src-templates-job-post-tsx": () => import("./../../../src/templates/job-post.tsx" /* webpackChunkName: "component---src-templates-job-post-tsx" */),
  "component---src-templates-join-us-tsx": () => import("./../../../src/templates/join-us.tsx" /* webpackChunkName: "component---src-templates-join-us-tsx" */),
  "component---src-templates-portfolio-tsx": () => import("./../../../src/templates/portfolio.tsx" /* webpackChunkName: "component---src-templates-portfolio-tsx" */)
}

